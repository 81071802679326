import { gql } from '@apollo/client'
import { Button } from '@nextui-org/react'
import { Logo } from 'components'
import { query, Route } from 'lib'
import { MeQuery } from 'lib/generated'
import type { GetServerSidePropsContext, NextPage } from 'next'
import Link from 'next/link'
import { useRouter } from 'next/router'

const meQuery = gql`
  query Me {
    me {
      id
    }
  }
`

const LogoSection = () => (
  <div className="text-center text-6xl">
    <Logo />
  </div>
)

const Auth = () => {
  const router = useRouter()
  return (
    <div className="mt-8 flex justify-center gap-8">
      <Button onPress={() => router.push(Route.Login)}>Log in</Button>
      <Button color="primary" onPress={() => router.push(Route.Signup)}>
        Sign up
      </Button>
    </div>
  )
}

const Home: NextPage = () => {
  return (
    <div className="pt-20">
      <LogoSection />
      <Auth />
    </div>
  )
}

export default Home

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  try {
    // Check if the user is authenticated, if so, send them to their workouts
    await query<MeQuery>(ctx, meQuery)
    return {
      redirect: {
        permanent: false,
        destination: Route.Home,
      },
    }
  } catch (err) {
    return {
      props: {},
    }
  }
}
